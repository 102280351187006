import { DownOutlined } from '@ant-design/icons';
import { media } from '@app/styles/themes/constants';
import styled from 'styled-components';
import { HeaderActionWrapper } from '../../../Header.styles';

export const ProfileDropdownHeader = styled(HeaderActionWrapper)`
  cursor: pointer;

  @media only screen and ${media.md} {
    border-radius: 50px;
    padding: 0.3125rem 0.2rem;
  }
`;

export const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 50%;

  background: conic-gradient(
    from -35.18deg at 50% 50%,
    #0f82e0 -154.36deg,
    #ff5252 24.13deg,
    #ffb155 118.76deg,
    #0f82e0 205.64deg,
    #ff5252 384.13deg
  );

  & > span {
    margin: 0.5px;
  }
`;

export const DownArrow = styled(DownOutlined)`
  color: var(--text-secondary-color);

  @media only screen and ${media.md} {
    color: var(--text-main-color);
  }
`;
