import { ClaimModel } from '@app/domain/ClaimModel';
import { differenceInDays } from 'date-fns';

export enum DunningFeeFactor {
  Simple = 0.5,
  Paymentplan = 0.7,
  Complex = 0.9,
  Court = 1.3,
}

export const getDunningFee = (dueAmount: number, factor: DunningFeeFactor): number => {
  let fee = 0;

  if (dueAmount === 0) {
    return 0;
  }

  if (factor === DunningFeeFactor.Paymentplan) {
    // only use 50% for payment plans
    dueAmount = dueAmount / 2;
  }

  // magic numbers = based on legal requirements
  if (dueAmount <= 50) {
    // absolute maximum of dunning fee AND expenses together: 30
    fee = Math.min(49 * factor, 30);
  } else if (dueAmount <= 500) {
    fee = 49 * factor;
  } else if (dueAmount <= 1000) {
    fee = 88 * factor;
  } else if (dueAmount <= 1500) {
    fee = 127 * factor;
  } else if (dueAmount <= 2000) {
    fee = 166 * factor;
  } else if (dueAmount <= 3000) {
    fee = 222 * factor;
  } else if (dueAmount <= 4000) {
    fee = 278 * factor;
  } else if (dueAmount <= 5000) {
    fee = 334 * factor;
  } else if (dueAmount <= 6000) {
    fee = 390 * factor;
  } else if (dueAmount <= 7000) {
    fee = 446 * factor;
  } else if (dueAmount <= 8000) {
    fee = 502 * factor;
  } else if (dueAmount <= 9000) {
    fee = 558 * factor;
  } else if (dueAmount <= 10000) {
    fee = 614 * factor;
  } else if (dueAmount <= 13000) {
    fee = 666 * factor;
  } else if (dueAmount <= 16000) {
    fee = 718 * factor;
  } else if (dueAmount <= 19000) {
    fee = 770 * factor;
  } else if (dueAmount <= 22000) {
    fee = 822 * factor;
  } else if (dueAmount <= 25000) {
    fee = 874 * factor;
  } else if (dueAmount <= 30000) {
    fee = 955 * factor;
  } else if (dueAmount <= 35000) {
    fee = 1036 * factor;
  } else if (dueAmount <= 40000) {
    fee = 1117 * factor;
  } else if (dueAmount <= 45000) {
    fee = 1198 * factor;
  } else if (dueAmount <= 50000) {
    fee = 1279 * factor;
  } else if (dueAmount <= 65000) {
    fee = 1373 * factor;
  } else if (dueAmount <= 80000) {
    fee = 1467 * factor;
  } else if (dueAmount <= 95000) {
    fee = 1561 * factor;
  } else if (dueAmount <= 110000) {
    fee = 1655 * factor;
  } else if (dueAmount <= 125000) {
    fee = 1749 * factor;
  } else if (dueAmount <= 140000) {
    fee = 1843 * factor;
  } else if (dueAmount <= 155000) {
    fee = 1937 * factor;
  } else if (dueAmount <= 170000) {
    fee = 2031 * factor;
  } else if (dueAmount <= 185000) {
    fee = 2125 * factor;
  } else if (dueAmount <= 200000) {
    fee = 2219 * factor;
  } else if (dueAmount <= 230000) {
    fee = 2351 * factor;
  } else if (dueAmount <= 260000) {
    fee = 2483 * factor;
  } else if (dueAmount <= 290000) {
    fee = 2615 * factor;
  } else if (dueAmount <= 320000) {
    fee = 2747 * factor;
  } else if (dueAmount <= 350000) {
    fee = 2879 * factor;
  } else if (dueAmount <= 380000) {
    fee = 3011 * factor;
  } else if (dueAmount <= 410000) {
    fee = 3143 * factor;
  } else if (dueAmount <= 440000) {
    fee = 3275 * factor;
  } else if (dueAmount <= 470000) {
    fee = 3407 * factor;
  } else if (dueAmount <= 500000) {
    fee = 3539 * factor;
  } else if (dueAmount <= 550000) {
    fee = 3704 * factor;
  } else if (dueAmount <= 600000) {
    fee = 3869 * factor;
  } else if (dueAmount <= 650000) {
    fee = 4034 * factor;
  } else if (dueAmount <= 700000) {
    fee = 4199 * factor;
  } else if (dueAmount <= 750000) {
    fee = 4364 * factor;
  } else if (dueAmount <= 800000) {
    fee = 4529 * factor;
  } else if (dueAmount <= 850000) {
    fee = 4694 * factor;
  } else if (dueAmount <= 900000) {
    fee = 4859 * factor;
  } else if (dueAmount <= 950000) {
    fee = 5024 * factor;
  } else if (dueAmount <= 1000000) {
    fee = 5189 * factor;
  } else if (dueAmount <= 1000000) {
    fee = 5189 * factor;
  } else if (dueAmount <= 1050000) {
    fee = 5354 * factor;
  } else if (dueAmount <= 1100000) {
    fee = 5519 * factor;
  } else if (dueAmount <= 1150000) {
    fee = 5684 * factor;
  } else if (dueAmount <= 1200000) {
    fee = 5849 * factor;
  } else if (dueAmount <= 1250000) {
    fee = 6014 * factor;
  } else if (dueAmount <= 1300000) {
    fee = 6179 * factor;
  } else if (dueAmount <= 1350000) {
    fee = 6344 * factor;
  } else if (dueAmount <= 1400000) {
    fee = 6509 * factor;
  } else if (dueAmount <= 1450000) {
    fee = 6674 * factor;
  } else if (dueAmount <= 1500000) {
    fee = 6839 * factor;
  } else if (dueAmount <= 1550000) {
    fee = 7004 * factor;
  } else if (dueAmount <= 1600000) {
    fee = 7169 * factor;
  } else if (dueAmount <= 1650000) {
    fee = 7334 * factor;
  } else if (dueAmount <= 1700000) {
    fee = 7499 * factor;
  } else if (dueAmount <= 1750000) {
    fee = 7664 * factor;
  } else if (dueAmount <= 1800000) {
    fee = 7829 * factor;
  } else if (dueAmount <= 1850000) {
    fee = 7994 * factor;
  } else if (dueAmount <= 1900000) {
    fee = 8159 * factor;
  } else if (dueAmount <= 1950000) {
    fee = 8324 * factor;
  } else if (dueAmount <= 2000000) {
    fee = 8489 * factor;
  } else if (dueAmount <= 2050000) {
    fee = 8654;
  } else if (dueAmount <= 2150000) {
    fee = 8984;
  } else if (dueAmount <= 2250000) {
    fee = 9314;
  } else if (dueAmount <= 2300000) {
    fee = 9479;
  } else if (dueAmount <= 2350000) {
    fee = 9644;
  } else if (dueAmount <= 2450000) {
    fee = 9974;
  } else if (dueAmount <= 2500000) {
    fee = 10139;
  } else if (dueAmount <= 2550000) {
    fee = 10304;
  } else if (dueAmount <= 2650000) {
    fee = 10634;
  } else if (dueAmount <= 2700000) {
    fee = 10799;
  } else if (dueAmount <= 2750000) {
    fee = 10964;
  } else if (dueAmount <= 2850000) {
    fee = 11294;
  } else if (dueAmount <= 2900000) {
    fee = 11459;
  } else if (dueAmount <= 2950000) {
    fee = 11624;
  }

  // absolute minimum: 15
  return Math.max(fee, 15);
};

const CLAIM_STATUS_ORDER: { [key: string]: string } = {
  FirstReminder: '1',
  SecondReminder: '2',
  ThirdReminder: '3',
  FirstDunning: '4',
  SecondDunning: '5',
  ThirdDunning: '6',
  FourtDunning: '7',
};

export const getCreditorInterest = (
  amountDue: number,
  dueDate: Date | null,
  interestRate: number | null,
  rateToDeduct?: number | null,
  frequencyForDeduction?: 'Monthly',
): {
  value: number;
  average: number;
} => {
  if (frequencyForDeduction && frequencyForDeduction !== 'Monthly') {
    return {
      value: 0,
      average: 0,
    };
  }

  if (dueDate === null || interestRate === null || amountDue <= 0) {
    return {
      value: 0,
      average: 0,
    };
  }

  let value: number = 0;

  // costs from duedate until now
  const today = new Date();
  const daysBetweenDueDateAndEndDate = differenceInDays(today, new Date(dueDate));
  value = value + amountDue * ((interestRate / 100) * (daysBetweenDueDateAndEndDate / 360));

  // for payment plans, calculate future interest
  if (rateToDeduct) {
    while (amountDue > 0) {
      const newValue = (amountDue * (interestRate / 100)) / 12;
      amountDue = amountDue - rateToDeduct - newValue;
      value = value + newValue;
    }
  }

  return {
    value: parseFloat(value.toFixed(2)),
    average: parseFloat(interestRate.toFixed(2)),
  };
};

export const getStatusToShow = (claim: ClaimModel, useSortMode?: boolean) => {
  let statusToShow = claim?.status || '';

  switch (statusToShow) {
    case 'EncashmentLevel':
      if (claim?.paymentPlans?.length && claim?.paymentPlans[0].isActive) {
        statusToShow = 'PaymentPlan';
      } else if (claim?.status !== 'Open') {
        const firstActivityWithClaimAction = claim?.activities?.find(
          (activity) => !activity.isPlanned && activity.claimAction,
        );
        if (firstActivityWithClaimAction && firstActivityWithClaimAction.claimAction) {
          statusToShow = firstActivityWithClaimAction.claimAction;
          if (useSortMode) {
            const orderedStatus: string | undefined = CLAIM_STATUS_ORDER[statusToShow];
            if (orderedStatus) {
              return orderedStatus;
            }
          }
        }
      }
      break;
    case 'Open':
    case 'ReminderLevel':
    case 'JudicialLevel':
      break;
    case 'Closed':
    case 'Paid':
      if (claim?.payments?.filter((payment) => payment.paymentType === 'Payout').length) {
        statusToShow = 'PaidOut';
      }
      break;
    default:
      break;
  }

  return statusToShow;
};
