import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Card } from 'components/common/Card/Card';
import { Col, Divider, Popconfirm, Row, Space, Typography, message } from 'antd';
import { BrandSpan, FormWrapper } from '@app/components/layouts/ModalLayout/ModalLayout.styles';
import { Button } from '@app/components/common/buttons/Button/Button.styles';
import { BankFilled, CheckCircleFilled } from '@ant-design/icons';
import { FONT_FAMILY, FONT_SIZE, FONT_WEIGHT } from '@app/styles/themes/constants';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { ClaimModel } from '@app/domain/ClaimModel';
import { createClaimActivityPublic, createClaimPaymentPlanPublic, getClaimPublic } from '@app/api/claims.api';
import { format } from 'date-fns';
import { Loading } from '@app/components/common/Loading';
import { PaymentModel, EnumPaymentPaymentType, NEGATIVE_PAYMENT_TYPES } from '@app/domain/PaymentModel';
import { Collapse, Panel } from '@app/components/common/Collapse/Collapse';
import { DunningFeeFactor, getCreditorInterest, getDunningFee } from '@app/utils/claimUtils';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { RadioButton, RadioGroup } from '@app/components/common/Radio/Radio';

const PayPage: React.FC = () => {
  const { t } = useTranslation();

  const formRef = useRef<HTMLDivElement>(null);

  const [showPaymentPlan, setShowPaymentPlan] = useState(false);
  const [claimSolved, setClaimSolved] = useState(false);
  const [claimLoading, setClaimLoading] = useState(false);
  const [paymentPlanAgreement, setPaymentPlanAgreement] = useState(false);

  const [selectedPaymentFrequency, setSelectedPaymentFrequency] = useState(3);
  const [paymentPlanCreditorInterest, setPaymentPlanCreditorInterest] = useState(0);

  const numberFormat = Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });

  const { claimId, claimReferencePrefix, claimReference } = useParams();
  const [claim, setClaim] = useState<ClaimModel>();
  const [isError, setIsError] = useState<boolean>(false);

  let availablePaymentPlanMonths = [2, 3, 4, 6, 12];
  if (claim?.creditor && claim.creditor.maximumPaymentPlanMonths !== 0) {
    availablePaymentPlanMonths = availablePaymentPlanMonths.filter(function (x) {
      return x <= (claim?.creditor?.maximumPaymentPlanMonths || 12);
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (claimId && claimReference) {
          let response;
          if (claimReferencePrefix) {
            response = await getClaimPublic({ claimId, claimReference: claimReferencePrefix + '/' + claimReference });
          } else {
            response = await getClaimPublic({ claimId, claimReference });
          }
          if (response) {
            setClaim(response as ClaimModel);
          }
        }
      } catch (error) {
        setIsError(true);
        console.log('error fetching claim details', error);
      }
    };

    fetchData();
  }, [claimId, claimReferencePrefix, claimReference]);

  const payments: PaymentModel[] | undefined = claim?.payments;

  // latest costs
  const latestDunningCostExpense = payments?.find(
    (payment) => payment.paymentType === EnumPaymentPaymentType.DunningCostExpense,
  );
  const latestDunningCostFee = payments?.find(
    (payment) => payment.paymentType === EnumPaymentPaymentType.DunningCostFee,
  );
  const latestDefaultInterest = payments?.find(
    (payment) => payment.paymentType === EnumPaymentPaymentType.DefaultInterest,
  );
  const latestCreditorExtras = payments?.find(
    (payment) => payment.paymentType === EnumPaymentPaymentType.ExistingCreditorExtras,
  );
  const latestCreditorInterest = payments?.find(
    (payment) => payment.paymentType === EnumPaymentPaymentType.CreditorInterest,
  );
  const latestDebtCollectorTax = payments?.find(
    (payment) => payment.paymentType === EnumPaymentPaymentType.DebtCollectorTax,
  );

  const incomingPayments = payments?.filter(
    (payment) =>
      payment.paymentType &&
      NEGATIVE_PAYMENT_TYPES.includes(payment.paymentType as EnumPaymentPaymentType) &&
      payment.paymentType !== EnumPaymentPaymentType.WriteOffDiscount,
  );

  const writeOffs = payments?.filter((payment) => payment.paymentType === EnumPaymentPaymentType.WriteOffDiscount);
  const writeOffsTotal: number =
    writeOffs?.reduce(function (a, b) {
      return a + b.amount;
    }, 0) || 0;

  const existingPayments = payments?.filter(
    (payment) => payment.paymentType === EnumPaymentPaymentType.ExistingPayments,
  );
  const existingPaymentsTotal: number =
    existingPayments?.reduce(function (a, b) {
      return a + b.amount;
    }, 0) || 0;

  const paymentPlanFees: number = getDunningFee(
    (claim?.originalAmountDue || 0) - (claim?.totalPaid || 0),
    DunningFeeFactor.Paymentplan,
  );

  useEffect(() => {
    setPaymentPlanCreditorInterest(
      getCreditorInterest(
        (claim?.originalAmountDue || 0) - (claim?.totalPaid || 0),
        claim?.dueDate || new Date(),
        claim?.interestRate || 0,
        ((claim?.totalPending || 0) - (latestCreditorInterest?.amount || 0) + paymentPlanFees) /
          selectedPaymentFrequency,
        'Monthly',
      ).value,
    );
  }, [claim, latestCreditorInterest, paymentPlanCreditorInterest, paymentPlanFees, selectedPaymentFrequency]);

  const handleClaimActivityButton = async (
    claim: ClaimModel,
    activityResponse: 'ReachedPromisedToPay' | 'ReachedPaymentPlanSetup',
  ) => {
    try {
      setClaimLoading(true);
      const resultActivity = await createClaimActivityPublic({
        claimId: claim.id,
        claimReference: claim.reference,
        activity: {
          activityType: 'Response',
          result: activityResponse,
          isPlanned: false,
        },
      });
      if (activityResponse === 'ReachedPaymentPlanSetup') {
        if (resultActivity?.id) {
          try {
            const resultPaymentPlan = await createClaimPaymentPlanPublic({
              claimId: claim.id,
              claimReference: claim.reference,
              paymentPlan: {
                numberOfPayments: selectedPaymentFrequency,
                frequency: 'Monthly',
              },
            });
            setClaimLoading(false);
            if (resultPaymentPlan?.id) {
              setClaimSolved(true);
              if (activityResponse === 'ReachedPaymentPlanSetup') {
                message.success(t('pay.successPaymentPlan'), 10000);
              } else {
                message.success(t('pay.success'), 10000);
              }
            } else {
              message.error(t('pay.errorPaymentPlan'), 10000);
            }
          } catch (e) {
            message.error(t('pay.errorPaymentPlan'), 10000);
          }
        }
      } else {
        message.success(t('pay.success'), 10000);
        setClaimSolved(true);
        setClaimLoading(false);
      }
    } catch (e) {
      setClaimLoading(false);
      message.error(
        <>
          {t('pay.loadingError')} <a href="mailto:support@debtist.de">{t('pay.loadingErrorContact')}</a>
        </>,
      );
    }
  };

  const hasPaymentPlan = Boolean(claim && claim.paymentPlans?.length);

  return (
    <>
      <PageTitle>{t('common.pay')}</PageTitle>
      {claim ? (
        <>
          <Space direction="vertical">
            <Row justify="center" style={{ fontWeight: 600 }}>
              {claim?.creditor?.imageUrl ? (
                <img
                  src={claim?.creditor?.imageUrl}
                  alt={claim?.creditor?.businessName}
                  style={{ display: 'block', marginBottom: 2, maxWidth: 170 }}
                />
              ) : (
                claim?.creditor?.businessName || claim?.creditor?.contactName
              )}
            </Row>
            <Row justify="center" style={{ marginBottom: 12 }}>
              <BrandSpan>powered by Debtist</BrandSpan>
            </Row>
          </Space>
          <FormWrapper>
            <Row gutter={[20, 20]}>
              <Col xs={24} md={12} xl={14}>
                <Card title={t('common.pay')}>
                  <Typography.Paragraph style={{ color: 'var(--text-nft-light-color)' }}>
                    {t('pay.greeting')} <b>{claim?.debtor?.businessName || claim?.debtor?.contactName}</b>,
                  </Typography.Paragraph>
                  <Typography.Paragraph style={{ color: 'var(--text-nft-light-color)' }}>
                    {t('pay.intro1')} <b>{claim?.creditor?.businessName || claim?.creditor?.contactName}</b>
                    {t('pay.intro2')} <i>{t('common.markAsPaid')}</i>.
                  </Typography.Paragraph>
                  <Typography.Paragraph style={{ color: 'var(--text-nft-light-color)' }}>
                    {t('pay.intro3')}
                  </Typography.Paragraph>
                  {claim?.invoiceCreatedAt && (
                    <Typography.Paragraph style={{ color: 'var(--text-nft-light-color)' }}>
                      {t('pay.invoiceDate')}: {format(new Date(claim?.invoiceCreatedAt), 'dd.MM.yyyy')}.<br />
                      {t('pay.claimTitle')}: {claim?.title}.
                    </Typography.Paragraph>
                  )}
                  {claim?.creditor?.maximumPaymentPlanMonths !== 1 && (
                    <>
                      <Row justify="center" style={{ marginBottom: 12, marginTop: 48 }}>
                        <Button
                          block
                          type={hasPaymentPlan ? 'default' : 'primary'}
                          disabled={hasPaymentPlan}
                          onClick={() => {
                            setShowPaymentPlan(true);
                            setTimeout(() => {
                              formRef?.current?.scrollIntoView({ behavior: 'smooth' });
                            }, 100);
                          }}
                        >
                          {hasPaymentPlan ? t('common.paymentPlanActive') : t('common.createPaymentPlan')}
                        </Button>
                      </Row>
                      <Row justify="center" style={{ marginBottom: 12, marginTop: 12 }}>
                        <SubtitleBalanceText>{t('common.or')}</SubtitleBalanceText>
                      </Row>
                    </>
                  )}
                  <Row justify="center">
                    <Popconfirm
                      placement="top"
                      title={t('pay.confirmPayment')}
                      okButtonProps={{ type: 'primary', block: true, style: { width: '48%' } }}
                      cancelButtonProps={{ block: true, style: { width: '48%' } }}
                      onConfirm={async () => handleClaimActivityButton(claim, 'ReachedPromisedToPay')}
                      okText={t('popconfirm.yes')}
                      cancelText={t('popconfirm.no')}
                    >
                      <Button
                        icon={<CheckCircleFilled />}
                        type="default"
                        block
                        loading={claimLoading}
                        disabled={claimSolved}
                      >
                        {t('common.markAsPaid')}
                      </Button>
                    </Popconfirm>
                  </Row>
                </Card>
              </Col>
              <Col xs={24} md={12} xl={10}>
                <Card title={t('common.summary')}>
                  <Typography.Paragraph style={{ color: 'var(--text-nft-light-color)' }}>
                    {t('claim.total')}:{' '}
                    <Typography.Paragraph
                      style={{ color: 'var(--primary-color)', fontWeight: 'bold', fontFamily: FONT_FAMILY.code }}
                    >
                      {numberFormat.format(claim?.totalPending || 0)}
                    </Typography.Paragraph>
                  </Typography.Paragraph>
                  <Typography.Paragraph style={{ color: 'var(--text-nft-light-color)' }}>
                    {t('pay.paymentReference')}:
                    <br />
                    <Typography.Paragraph
                      copyable
                      style={{
                        color: 'var(--primary-color)',
                        fontWeight: 'bold',
                        fontFamily: FONT_FAMILY.code,
                      }}
                    >
                      {claim?.reference}
                    </Typography.Paragraph>
                  </Typography.Paragraph>
                  <Typography.Paragraph style={{ color: 'var(--text-nft-light-color)' }}>
                    {t('pay.iban')}:
                    <br />
                    <Typography.Paragraph
                      copyable
                      style={{
                        color: 'var(--primary-color)',
                        fontWeight: 'bold',
                        fontFamily: FONT_FAMILY.code,
                      }}
                    >
                      {claim.reference?.endsWith('CS') ? 'DE43 5506 0417 0100 2411 21' : 'DE88 1004 0000 0250 3142 01'}
                    </Typography.Paragraph>
                  </Typography.Paragraph>
                  {!claim.reference?.endsWith('CS') && (
                    <Typography.Paragraph style={{ color: 'var(--text-nft-light-color)' }}>
                      {t('pay.bic')}:
                      <br />
                      <Typography.Paragraph
                        copyable
                        style={{
                          color: 'var(--primary-color)',
                          fontWeight: 'bold',
                          fontFamily: FONT_FAMILY.code,
                        }}
                      >
                        {'COBADEFFXXX'}
                      </Typography.Paragraph>
                    </Typography.Paragraph>
                  )}
                  <Typography.Paragraph style={{ color: 'var(--text-nft-light-color)' }}>
                    {t('pay.recipient')}:
                    <br />
                    <Typography.Paragraph
                      copyable
                      style={{
                        color: 'var(--primary-color)',
                        fontWeight: 'bold',
                        fontFamily: FONT_FAMILY.code,
                      }}
                    >
                      {claim.reference?.endsWith('CS') ? 'CS Collection Services' : 'Debtist'}
                    </Typography.Paragraph>
                  </Typography.Paragraph>
                  <Divider />
                  <Typography.Paragraph style={{ color: 'var(--text-nft-light-color)' }}>
                    {t('pay.due')}: <b>{t('pay.now')}</b>
                    <br />
                    {(!claim || !claim.paymentPlans || claim.paymentPlans.length === 0) &&
                      claim?.creditor?.maximumPaymentPlanMonths !== 1 && (
                        <SubtitleBalanceText>
                          {t('pay.alternative')}:{' '}
                          <a
                            onClick={() => {
                              setShowPaymentPlan(true);
                              setTimeout(() => {
                                formRef?.current?.scrollIntoView({ behavior: 'smooth' });
                              }, 100);
                            }}
                          >
                            {t('pay.splitPayment')}
                          </a>
                          .
                        </SubtitleBalanceText>
                      )}
                  </Typography.Paragraph>

                  <Divider />
                  {renderPaymentExplanation()}
                </Card>
                <Row style={{ marginTop: 20, display: 'flex', justifyContent: 'flex-end' }}>
                  <a
                    style={{ color: 'var(--text-nft-light-color)' }}
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.debtist.de/privacy/"
                  >
                    {t('signup.privacyOPolicy')}
                  </a>
                </Row>
              </Col>

              {showPaymentPlan && (
                <Col ref={formRef} xs={24} md={24} xl={24}>
                  <Divider style={{ marginTop: 25 }}>
                    <Typography.Title level={5} style={{ color: 'var(--text-nft-light-color)' }}>
                      {t('common.paymentPlan')}
                    </Typography.Title>
                  </Divider>
                  <Card autoHeight={false}>
                    <Row>
                      <Col xs={24} md={14} xl={14}>
                        <Row>
                          <Typography.Paragraph style={{ color: 'var(--text-nft-light-color)' }}>
                            {t('pay.paymentPlanDurationDescription')}:
                          </Typography.Paragraph>
                        </Row>
                        <Row>
                          <RadioGroup
                            value={selectedPaymentFrequency}
                            onChange={(e) => setSelectedPaymentFrequency(e.target.value)}
                            size="small"
                          >
                            {availablePaymentPlanMonths.map((month) => (
                              <RadioButton key={month} value={month}>
                                {month + ' ' + t('common.months')}
                              </RadioButton>
                            ))}
                          </RadioGroup>
                          {/*
                          <Slider
                            tooltip={{ open: false }}
                            style={{ flex: 1, marginRight: 20, fontSize: 10, paddingBottom: 30 }}
                            handleStyle={{ borderColor: 'var(--primary-color)' }}
                            included={false}
                            autoFocus
                            defaultValue={selectedPaymentFrequency}
                            value={selectedPaymentFrequency}
                            onChange={(value: [number, number]) => {
                              console.log(value);
                              setSelectedPaymentFrequency(value[0]);
                            }}
                            range={false}
                            step={25}
                            marks={{
                              0: {
                                label: (
                                  <>
                                    2
                                    <br />
                                    Monate
                                  </>
                                ),
                              },
                              25: {
                                label: (
                                  <>
                                    3
                                    <br />
                                    Monate
                                  </>
                                ),
                              },
                              50: {
                                label: (
                                  <>
                                    4
                                    <br />
                                    Monate
                                  </>
                                ),
                              },
                              75: {
                                label: (
                                  <>
                                    6
                                    <br />
                                    Monate
                                  </>
                                ),
                              },
                              100: {
                                label: (
                                  <>
                                    12
                                    <br />
                                    Monate
                                  </>
                                ),
                              },
                            }}
                          />
                          */}
                        </Row>
                        <Row style={{ marginBottom: 6, marginTop: 12 }}>
                          <Checkbox
                            checked={paymentPlanAgreement}
                            onChange={(e) => setPaymentPlanAgreement(e.target.checked)}
                          >
                            <SubtitleBalanceText style={{ display: 'block', maxWidth: 350 }}>
                              {t('pay.paymentPlanAgree')}
                            </SubtitleBalanceText>
                          </Checkbox>
                        </Row>
                        <Row style={{ marginBottom: 6, marginTop: 12 }}>
                          <Button
                            icon={<BankFilled />}
                            loading={claimLoading}
                            disabled={claimSolved || !paymentPlanAgreement}
                            type="primary"
                            onClick={async () => handleClaimActivityButton(claim, 'ReachedPaymentPlanSetup')}
                          >
                            {t('common.submitPaymentPlan')}
                          </Button>
                          {/*
                            <Button type="text" onClick={() => setShowPaymentPlan(false)}>
                            {t('common.cancel')}
                            </Button>
                          */}
                        </Row>
                        <Row style={{ marginBottom: 6, marginTop: 12 }}>
                          <SubtitleBalanceText style={{ display: 'block', maxWidth: 350 }}>
                            {t('pay.paymentPlanSuffix')}
                          </SubtitleBalanceText>
                        </Row>
                      </Col>
                      <Col xs={24} md={10} xl={10}>
                        <Row>
                          <Typography.Paragraph style={{ color: 'var(--text-nft-light-color)' }}>
                            {t('pay.currentRate')}:
                          </Typography.Paragraph>
                        </Row>
                        <Row>
                          <Typography.Title level={2}>
                            {numberFormat.format(
                              ((claim?.totalPending || 0) -
                                (latestCreditorInterest?.amount || 0) +
                                paymentPlanFees +
                                paymentPlanCreditorInterest) /
                                selectedPaymentFrequency,
                            )}{' '}
                            / {t('common.month')}
                          </Typography.Title>
                          <SubtitleBalanceText>
                            {latestDebtCollectorTax && (
                              <>
                                {t('pay.excludingTaxes')}
                                <br />
                                <br />
                              </>
                            )}
                            {t('pay.paymentPlanNote')}
                            <br />
                            {t('pay.paymentPlanWarning')}
                            <br />
                            <br />
                            {t('pay.paymentPlanExpenses')}: {numberFormat.format(paymentPlanFees)}
                            {latestDebtCollectorTax && ' ' + t('pay.excludingTaxes')}
                          </SubtitleBalanceText>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                  <br />
                </Col>
              )}
            </Row>
          </FormWrapper>
        </>
      ) : isError || !claimId || !claimReference ? (
        <>
          <SubtitleBalanceText style={{ marginTop: 12 }}>
            {t('pay.loadingError')} <a href="mailto:support@debtist.de">{t('pay.loadingErrorContact')}</a>
          </SubtitleBalanceText>
        </>
      ) : (
        <>
          <Loading />
          <SubtitleBalanceText style={{ marginTop: 12 }}>{t('pay.loading')}...</SubtitleBalanceText>
        </>
      )}
    </>
  );

  function renderPaymentExplanation() {
    return (
      <CollapseWrapper ghost>
        <Panel header={t('pay.explainCosts')} key="1">
          <Typography.Text style={{ fontSize: 14 }}>
            {t('claim.originalAmountDue')},
            <br />
            {claim?.title}: <b>{numberFormat.format(claim?.originalAmountDue || 0)}</b>
          </Typography.Text>
          {existingPaymentsTotal !== 0 && (
            <div>
              <Typography.Text style={{ fontSize: 14 }}>
                {t('claim.payment.ExistingPayments')}: {numberFormat.format(existingPaymentsTotal)}{' '}
              </Typography.Text>
            </div>
          )}
          {latestCreditorExtras && (
            <div>
              <Typography.Text style={{ fontSize: 14 }}>
                {t('claim.payment.ExistingCreditorExtras')}: +{numberFormat.format(latestCreditorExtras.amount)}{' '}
              </Typography.Text>
              <SubtitleBalanceText>
                {latestCreditorExtras.paymentDate && format(new Date(latestCreditorExtras.paymentDate), 'dd.MM.yyyy')}
              </SubtitleBalanceText>
            </div>
          )}
          {latestDunningCostExpense && (
            <div>
              <Typography.Text style={{ fontSize: 14 }}>
                {t('claim.payment.DunningCostExpense')}: +{numberFormat.format(latestDunningCostExpense.amount)}{' '}
              </Typography.Text>
              <SubtitleBalanceText>
                {latestDunningCostExpense.paymentDate &&
                  format(new Date(latestDunningCostExpense.paymentDate), 'dd.MM.yyyy')}
              </SubtitleBalanceText>
            </div>
          )}
          {latestDunningCostFee && (
            <div>
              <Typography.Text style={{ fontSize: 14 }}>
                {t('claim.payment.DunningCostFee')}: +{numberFormat.format(latestDunningCostFee.amount)}{' '}
              </Typography.Text>
              <SubtitleBalanceText>
                {latestDunningCostFee.paymentDate && format(new Date(latestDunningCostFee.paymentDate), 'dd.MM.yyyy')}
              </SubtitleBalanceText>
            </div>
          )}
          {latestDefaultInterest && (
            <div>
              <Typography.Text style={{ fontSize: 14 }}>
                {t('claim.payment.DefaultInterest')}: +{numberFormat.format(latestDefaultInterest.amount)}{' '}
              </Typography.Text>
              <SubtitleBalanceText>
                {latestDefaultInterest.paymentDate && format(new Date(latestDefaultInterest.paymentDate), 'dd.MM.yyyy')}
              </SubtitleBalanceText>
            </div>
          )}
          {latestCreditorInterest && (
            <div>
              <Typography.Text style={{ fontSize: 14 }}>
                {t('claim.payment.CreditorInterest')}: +{numberFormat.format(latestCreditorInterest.amount)}{' '}
              </Typography.Text>
              <SubtitleBalanceText>
                {latestCreditorInterest.paymentDate &&
                  format(new Date(latestCreditorInterest.paymentDate), 'dd.MM.yyyy')}
              </SubtitleBalanceText>
            </div>
          )}
          {latestDebtCollectorTax && (
            <div>
              <Typography.Text style={{ fontSize: 14 }}>
                {t('claim.payment.DebtCollectorTax')}: +{numberFormat.format(latestDebtCollectorTax.amount)}{' '}
              </Typography.Text>
              <SubtitleBalanceText>
                {latestDebtCollectorTax.paymentDate &&
                  format(new Date(latestDebtCollectorTax.paymentDate), 'dd.MM.yyyy')}
              </SubtitleBalanceText>
            </div>
          )}
          {incomingPayments &&
            Array.isArray(incomingPayments) &&
            incomingPayments.length > 0 &&
            incomingPayments
              ?.sort((a, b) => {
                if (a.paymentDate === null || b.paymentDate === null) {
                  return 0;
                }
                return new Date(a.paymentDate) < new Date(b.paymentDate) ? 1 : -1;
              })
              .map((payment) => (
                <div key={payment.id}>
                  <Typography.Text style={{ fontSize: 14 }}>
                    {t('claim.incomingPayment')}: {numberFormat.format(payment.amount)}{' '}
                  </Typography.Text>
                  <SubtitleBalanceText>
                    {payment.paymentDate && format(new Date(payment.paymentDate), 'dd.MM.yyyy')}
                  </SubtitleBalanceText>
                </div>
              ))}
          {writeOffsTotal !== 0 && (
            <Typography.Text style={{ fontSize: 14 }}>
              {t('claim.writeOffs')}: {numberFormat.format(writeOffsTotal)}
            </Typography.Text>
          )}
        </Panel>
      </CollapseWrapper>
    );
  }
};

export const SubtitleBalanceText = styled(Typography.Text)`
  font-family: ${FONT_FAMILY.secondary};

  font-size: ${FONT_SIZE.xs};

  font-weight: ${FONT_WEIGHT.regular};

  color: var(--text-nft-light-color);
`;

export const CollapseWrapper = styled(Collapse)`
  width: auto;
  margin-bottom: 10px;
`;

export default PayPage;
