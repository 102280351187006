import { LeftOutlined } from '@ant-design/icons';
import { Card } from '@app/components/common/Card/Card';
import { Button } from '@app/components/common/buttons/Button/Button';
import { ProfileInfo } from '@app/components/profile/profileCard/ProfileInfo/ProfileInfo';
import { ProfileNav } from '@app/components/profile/profileCard/ProfileNav/ProfileNav';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import { slideIn } from '@app/styles/animation';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const ProfileLayout: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);

  const { t } = useTranslation();
  const { isTablet: isTabletOrHigher, mobileOnly } = useResponsive();
  const location = useLocation();
  const navigate = useNavigate();

  const { isTablet } = useResponsive();

  const isTitleShown = isTabletOrHigher || (mobileOnly && location.pathname === '/profile');
  const isMenuShown = isTabletOrHigher || (mobileOnly && location.pathname !== '/profile');

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const animationTimeout = setTimeout(() => {
      setAnimate(true);
    }, 600);

    return () => clearTimeout(animationTimeout);
  }, []);

  useEffect(() => {
    isTablet && location.pathname === '/profile' && navigate('personal-info');
  }, [isTablet, location.pathname, navigate]);

  return (
    <>
      <Btn hidden={isTitleShown} icon={<LeftOutlined />} type="text" onClick={() => navigate('/profile')}>
        {t('common.back')}
      </Btn>

      <Row gutter={[30, 30]}>
        <Col xs={24} md={24} xl={24}>
          <Card padding={'30px 60px'}>
            <CustomRow>
              <Col xs={24} xl={8}>
                <ProfileInfo profileData={user} />
              </Col>
              <Col xs={24} xl={16}>
                <ProfileNav />
              </Col>
            </CustomRow>
          </Card>
        </Col>
        <AnimatedCol hidden={!isMenuShown || !animate} xs={24} className={animate ? 'slide-in' : ''}>
          <Outlet />
        </AnimatedCol>
      </Row>
    </>
  );
};

const CustomRow = styled(Row)`
  align-items: flex-start;
  justify-content: center;
`;

const AnimatedCol = styled(Col)`
  &.slide-in {
    animation: ${slideIn} 0.5s ease-out;
  }
`;

const Btn = styled(Button)`
  font-size: 1rem;
  margin-bottom: 1rem;
  font-weight: 600;
  padding: 0;
  height: unset;
  color: var(--secondary-color);
`;

export default ProfileLayout;
