import { httpApi } from '@app/api/http.api';
import { ClaimModel } from '@app/domain/ClaimModel';
import { Key } from 'react';

export interface AuthData {
  username: string;
  password: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  newPassword: string;
}

export interface GetUserClaimsFilter {
  creditorFeedbackRequired?: boolean;
  status?: string;
  stage?: string;
  payments?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    some?: any;
  };
  paymentPlans?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    some?: any;
  };
  searchString?: string;
}

export interface GetUserClaimsByCreditorRequest {
  userId: string;
  filters?: GetUserClaimsFilter[];
  orderBy?: { property: Key; order: 'asc' | 'desc' };
  skip?: number;
  take?: number;
}

export type GetUserClaimsByCreditorResponse = Array<ClaimModel>;

export interface GetUserRequest {
  userId: string;
}

export interface GetUserResponse {
  address?: {
    id: string;
  };
  businessName?: string;
  businessType?: string;
  comment?: string;
  contactName?: string;
  createdAt: Date;
  email?: string;
  id: string;
  industry?: string;
  name?: string;
  paymentInformation?: {
    id: string;
  };
  isVerified?: boolean;
  phone?: string;
  roles: string[];
  updatedAt: Date;
  username: string;
  analyticalRecipients?: string[];
  financialRecipients?: string[];
  operationalRecipients?: string[];
}

// for now, extend the GetUserResponse, as its the same
export interface UpdateUserRequest extends Partial<GetUserResponse> {
  userId: string;
}

export interface CreateUserRequest extends Partial<GetUserResponse> {
  password: string;
}

export interface CreateUserResponse {
  id: string;
}

export interface UpdateUserResponse extends GetUserResponse {}

export interface LoginRequest {
  username: string;
  password: string;
}

export interface LoginResponse {
  accessToken: string;
  id: string;
  roles: string[];
  username: string;
}

export const login = (loginPayload: LoginRequest): Promise<LoginResponse> =>
  httpApi.post<LoginResponse>('login', { ...loginPayload }).then(({ data }) => data);

export const getUser = (getUserPayload: GetUserRequest): Promise<GetUserResponse> =>
  httpApi.get<GetUserResponse>('users/' + getUserPayload.userId).then(({ data }) => data);

export const createUser = (createUserPayload: CreateUserRequest): Promise<CreateUserResponse> =>
  httpApi.post<UpdateUserResponse>('users', createUserPayload).then(({ data }) => data);

export const updateUser = (updateUserPayload: UpdateUserRequest): Promise<UpdateUserResponse> =>
  httpApi
    .patch<UpdateUserResponse>(
      'users/' + updateUserPayload.userId,
      Object.fromEntries(Object.entries(updateUserPayload).filter((e) => e[0] != 'userId')),
    )
    .then(({ data }) => data);

export const getUserClaimsAsCreditor = (
  getUserClaimsByCreditorPayload: GetUserClaimsByCreditorRequest,
): Promise<GetUserClaimsByCreditorResponse> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parameters: any = {};
  getUserClaimsByCreditorPayload.filters?.map((item) => {
    if (item.status !== null && item.status !== '') {
      parameters['where[status]'] = item.status;
    }
    if (item.stage !== null && item.stage !== '') {
      parameters['where[stage]'] = item.stage;
    }
    if (item.searchString) {
      parameters['searchString'] = item.searchString;
    }
    if (item.payments) {
      parameters['where[payments][some][paymentType]'] = item.payments.some?.paymentType;
    }
    if (item.paymentPlans) {
      parameters['where[paymentPlans][some]'] = 'true';
    }
    if (item.creditorFeedbackRequired === true || item.creditorFeedbackRequired === false) {
      parameters['where[creditorFeedbackRequired]'] = item.creditorFeedbackRequired;
    }
  });
  if (getUserClaimsByCreditorPayload.skip) {
    parameters['skip'] = getUserClaimsByCreditorPayload.skip;
  }
  if (getUserClaimsByCreditorPayload.take) {
    parameters['take'] = getUserClaimsByCreditorPayload.take;
  }

  if (getUserClaimsByCreditorPayload.orderBy) {
    parameters['orderBy[' + getUserClaimsByCreditorPayload.orderBy.property + ']'] =
      getUserClaimsByCreditorPayload.orderBy.order;
  }

  return httpApi
    .get<GetUserClaimsByCreditorResponse>('users/' + getUserClaimsByCreditorPayload.userId + '/claimsAsCreditor', {
      params: parameters ? parameters : null,
    })
    .then(({ data }) => data);
};

// not yet implemented
export const signUp = (signUpData: SignUpRequest): Promise<undefined> =>
  httpApi.post<undefined>('signUp', { ...signUpData }).then(({ data }) => data);

export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<undefined> =>
  httpApi.post<undefined>('forgotPassword', { ...resetPasswordPayload }).then(({ data }) => data);

export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
  httpApi.post<undefined>('verifySecurityCode', { ...securityCodePayload }).then(({ data }) => data);

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
  httpApi.post<undefined>('setNewPassword', { ...newPasswordData }).then(({ data }) => data);
