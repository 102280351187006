import { UserModel } from '@app/domain/UserModel';
import Cookies from 'js-cookie';

export const persistToken = (token: string): void => {
  Cookies.set('accessToken', token, {
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
    expires: 7,
  });
};

export const persistIntegrationId = (id: string): void => {
  Cookies.set('integrationId', id, {
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
    expires: 360,
  });
};

export const persistIntegrationType = (type: string): void => {
  Cookies.set('integrationType', type, {
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
    expires: 360,
  });
};

export const readToken = (): string => {
  return Cookies.get('accessToken') || '';
};

export const readIntegrationId = (): string => {
  return Cookies.get('integrationId') || '';
};

export const readIntegrationType = (): string => {
  return Cookies.get('integrationType') || '';
};

export const persistUser = (user: UserModel): void => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const readUser = (): UserModel | null => {
  const storedUser = localStorage.getItem('user');
  if (storedUser) {
    return JSON.parse(storedUser);
  }
  return null;
};

export const deleteToken = (): void =>
  Cookies.remove('accessToken', {
    path: '/',
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  });
export const deleteUser = (): void => localStorage.removeItem('user');
export const deleteIntegrationId = (): void =>
  Cookies.remove('integrationId', {
    path: '/',
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  });
