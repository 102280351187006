import { DollarOutlined, SecurityScanOutlined, UserOutlined } from '@ant-design/icons';
import React from 'react';
const primaryColor = 'var(--primary-color)';

interface ProfileNavItem {
  id: number;
  name: string;
  icon: React.ReactNode;
  color: 'primary' | 'error' | 'warning' | 'success';
  href: string;
  disabled: boolean;
}
export const profileNavData: ProfileNavItem[] = [
  {
    id: 1,
    name: 'profile.nav.personalInfo.title',
    icon: <UserOutlined />,
    color: 'primary',
    href: 'personal-info',
    disabled: false,
  },
  {
    id: 2,
    name: 'profile.nav.integrationSettings.title',
    icon: <SecurityScanOutlined />,
    color: 'primary',
    href: 'integration-settings',
    disabled: false,
  },
  // {
  //   id: 3,
  //   name: 'profile.nav.notifications.title',
  //   icon: <BellOutlined />,
  //   color: 'error',
  //   href: 'notifications',
  //   disabled: false,
  // },
  {
    id: 3,
    name: 'profile.nav.payments.title',
    icon: <DollarOutlined color={primaryColor} />,
    color: 'primary',
    href: 'payments',
    disabled: false,
  },
];
