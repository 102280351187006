import { PrepareAction, createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ResetPasswordRequest,
  login,
  LoginRequest,
  resetPassword,
  verifySecurityCode,
  SecurityCodePayload,
  NewPasswordData,
  setNewPassword,
  GetUserRequest,
  getUser,
  getUserClaimsAsCreditor,
  GetUserClaimsByCreditorRequest,
  UpdateUserRequest,
  updateUser,
  CreateUserRequest,
  createUser,
} from '@app/api/user.api';
import {
  deleteToken,
  deleteUser,
  persistToken,
  persistUser,
  readToken,
  readUser,
} from '@app/services/localStorage.service';
import { setClaimsAsCreditorDunning, setClaimsAsCreditorReminders } from './claimSlice';
import { UserModel } from '@app/domain/UserModel';

export interface AuthSlice {
  token: string | null;
  user: UserModel | null;
}

const initialState: AuthSlice = {
  token: readToken(),
  user: readUser(),
};

export const doLogin = createAsyncThunk('auth/doLogin', async (loginPayload: LoginRequest, { dispatch }) =>
  login(loginPayload).then((response) => {
    dispatch(setUser({ id: response.id, username: response.username, email: response.username }));
    persistToken(response.accessToken);

    return response.accessToken;
  }),
);

export const doGetUser = createAsyncThunk('auth/doGetUser', async (getUserPayload: GetUserRequest, { dispatch }) =>
  getUser(getUserPayload)
    .then((response) => {
      dispatch(setUser(response));
    })
    .catch(() => {
      dispatch(doLogout());
    }),
);

export const doCreateUser = createAsyncThunk('auth/doCreateUser', async (createUserPayload: CreateUserRequest) =>
  createUser(createUserPayload),
);

export const doUpdateUser = createAsyncThunk(
  'auth/doUpdateUser',
  async (updateUserPayload: UpdateUserRequest, { dispatch }) =>
    updateUser(updateUserPayload)
      .then((response) => {
        dispatch(setUser(response));
      })
      .catch((e) => {
        console.log('error updating user', e);
      }),
);

export const doGetUserClaimsAsCreditor = createAsyncThunk(
  'auth/doGetUserClaimsAsCreditor',
  async (getUserClaimsAsCreditorPayload: GetUserClaimsByCreditorRequest, { dispatch }) =>
    getUserClaimsAsCreditor(getUserClaimsAsCreditorPayload).then((response) => {
      // probably not the cleanest solution
      if (getUserClaimsAsCreditorPayload.filters?.[0]?.stage === 'Reminder') {
        dispatch(setClaimsAsCreditorReminders(response));
      } else {
        dispatch(setClaimsAsCreditorDunning(response));
      }
    }),
);

// the following are not yet implemented
export const doResetPassword = createAsyncThunk(
  'auth/doResetPassword',
  async (resetPassPayload: ResetPasswordRequest) => resetPassword(resetPassPayload),
);

export const doVerifySecurityCode = createAsyncThunk(
  'auth/doVerifySecurityCode',
  async (securityCodePayload: SecurityCodePayload) => verifySecurityCode(securityCodePayload),
);

export const doSetNewPassword = createAsyncThunk('auth/doSetNewPassword', async (newPasswordData: NewPasswordData) =>
  setNewPassword(newPasswordData),
);

export const doLogout = createAsyncThunk('auth/doLogout', (payload, { dispatch }) => {
  deleteToken();
  deleteUser();
  dispatch(setUser(null));
});

export const setUser = createAction<PrepareAction<UserModel>>('auth/setUser', (newUser) => {
  persistUser(newUser);

  return {
    payload: newUser,
  };
});

const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload;
    });
    builder.addCase(doLogout.fulfilled, (state) => {
      state.token = '';
    });
    builder.addCase(setUser, (state, action) => {
      state.user = action.payload;
    });
  },
});

export default authSlice.reducer;
