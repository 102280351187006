import { httpApi } from '@app/api/http.api';
import { ActivityModel } from '@app/domain/ActivityModel';
import { ClaimAggregateModel } from '@app/domain/ClaimAggregateModel';
import { ClaimModel } from '@app/domain/ClaimModel';
import { PaymentAggregateModel } from '@app/domain/PaymentAggregateModel';
import { PaymentModel } from '@app/domain/PaymentModel';
import { PaymentPlanModel } from '@app/domain/PaymentPlanModel';

export interface GetClaimRequest {
  claimId: string;
}

export interface GetClaimPublicRequest {
  claimId: string;
  claimReference: string;
}

export interface GetClaimPaymentPlansRequest {
  claimId: string;
}

export interface GetClaimResponse {
  activities?: ActivityModel[];
  claimType?: string;
  comment?: string;
  createdAt: Date;
  creditor?: {
    id: string;
    name: string;
  };
  debtor?: {
    id: string;
    name?: string;
  };
  dueDate?: Date;
  id: string;
  invoiceCreatedAt?: Date;
  manager?: {
    id: string;
  };
  originalAmountDue?: number;
  payments?: PaymentModel[];
  reference?: string;
  status?: string;
  title: string;
  totalPaid: number;
  totalPending: number;
  updatedAt: Date;
}

export interface GetClaimAggregateRequest {
  creditorId: string;
}

export interface GetClaimAggregateResponse {
  total: ClaimAggregateModel;
  open: ClaimAggregateModel;
  closed: ClaimAggregateModel;
  paid: PaymentAggregateModel;
  paidOut: PaymentAggregateModel;
  claimsPaid: ClaimAggregateModel;
  reminderLevel: ClaimAggregateModel;
  encashmentLevel: ClaimAggregateModel;
  judicialLevel: ClaimAggregateModel;
  paymentPlan: ClaimAggregateModel;
  firstEncashmentLevel: ClaimAggregateModel;
  secondEncashmentLevel: ClaimAggregateModel;
  thirdEncashmentLevel: ClaimAggregateModel;
  fourthEncashmentLevel: ClaimAggregateModel;
}

export interface CreateClaimActivityRequest {
  activity?: ActivityModel;
  claimId: string;
}

export interface CreateClaimActivityResponse {
  id?: string;
}

export interface CreateClaimActivityPublicRequest {
  activity?: ActivityModel;
  claimId: string;
  claimReference: string;
}

export interface CreateClaimActivityPublicResponse {
  id?: string;
}

export interface CreateClaimPaymentPlanPublicRequest {
  paymentPlan?: PaymentPlanModel;
  claimId: string;
  claimReference: string;
}

export interface CreateClaimPaymentPlanPublicResponse {
  id?: string;
}

export type GetClaimPaymentPlansResponse = Array<PaymentPlanModel>;

export interface CreateOwnClaimRequest {
  claimType?: string | 'Loan' | 'Service' | 'Goods' | null;
  comment?: string;
  debtor?: CreateOwnClaimRequestDebtor;
  dueDate?: Date;
  invoiceCreatedAt?: Date;
  taxRate?: number;
  title?: string;
  totalPaid?: number;
  totalPending?: number;
  originalAmountDue?: number;
  interestRate?: number;
  status?: 'Open';
}

export interface CreateOwnClaimRequestDebtor {
  id?: string;
  address?: {
    addressLine1?: string;
    addressLine2?: string;
    country?: string;
    postalcode?: string;
    town?: string;
  };
  businessName?: string;
  businessType?: string;
  comment?: string;
  contactName?: string;
  email?: string;
  industry?: string;
  paymentInformation?: {
    account?: string;
    id?: string;
    method?: string;
  };
  phone?: string;
}

export interface CreateOwnClaimResponse {
  total: ClaimAggregateModel;
}

export interface ClaimUploadResponse {
  failed: number;
  success: number;
  errorMessages?: { id: number | string; message: string }[];
}

export const getClaim = (getClaimPayload: GetClaimRequest): Promise<GetClaimResponse> =>
  httpApi.get<GetClaimResponse>('claims/' + getClaimPayload.claimId).then(({ data }) => data);

export const getClaimAggregate = (
  getClaimAggregatePayload: GetClaimAggregateRequest,
): Promise<GetClaimAggregateResponse> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parameters: any = {};
  parameters['where[creditor][id]'] = getClaimAggregatePayload.creditorId;

  return httpApi
    .get<GetClaimAggregateResponse>('claims/aggregate', {
      params: parameters ? parameters : null,
    })
    .then(({ data }) => data);
};

export const getClaimPublic = (getClaimPayload: GetClaimPublicRequest): Promise<GetClaimResponse> =>
  httpApi
    .get<GetClaimResponse>('claims/public/' + getClaimPayload.claimId, {
      params: { reference: getClaimPayload.claimReference },
    })
    .then(({ data }) => data);

export const createClaimActivities = (
  createClaimActivityPayload: CreateClaimActivityRequest,
): Promise<CreateClaimActivityResponse> | undefined =>
  httpApi
    .post('claims/' + createClaimActivityPayload.claimId + '/activities', {
      ...createClaimActivityPayload.activity,
    })
    .then(({ data }) => data);

export const createClaimActivityPublic = (
  createClaimActivityPublicPayload: CreateClaimActivityPublicRequest,
): Promise<CreateClaimActivityPublicResponse> | undefined =>
  httpApi
    .post('claims/public/' + createClaimActivityPublicPayload.claimId + '/activity', {
      claim: { reference: createClaimActivityPublicPayload.claimReference },
      ...createClaimActivityPublicPayload.activity,
    })
    .then(({ data }) => data);

export const getClaimPaymentPlans = (
  createClaimActivityPayload: GetClaimPaymentPlansRequest,
): Promise<GetClaimPaymentPlansResponse> | undefined =>
  httpApi.get('claims/' + createClaimActivityPayload.claimId + '/paymentPlans').then(({ data }) => data);

export const createClaimPaymentPlanPublic = (
  createClaimPaymentPlanPublicPayload: CreateClaimPaymentPlanPublicRequest,
): Promise<CreateClaimPaymentPlanPublicResponse> | undefined =>
  httpApi
    .post('claims/public/' + createClaimPaymentPlanPublicPayload.claimId + '/paymentPlan', {
      claim: { reference: createClaimPaymentPlanPublicPayload.claimReference },
      ...createClaimPaymentPlanPublicPayload.paymentPlan,
    })
    .then(({ data }) => data);

export const createOwnClaim = (createOwnClaimPayload: CreateOwnClaimRequest): Promise<ClaimModel> =>
  httpApi.post<ClaimModel>('claims/createOwn', { ...createOwnClaimPayload }).then(({ data }) => data);
