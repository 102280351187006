import styled, { css, keyframes } from 'styled-components';
import { Button } from 'components/common/buttons/Button/Button';
import { media } from '@app/styles/themes/constants';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scaleX(0.5);
  }
  to {
    opacity: 1;
    transform: scaleX(1);
  }
`;

interface BtnProps {
  isActive: boolean;
  color: 'primary' | 'error' | 'warning' | 'success';
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  align-items: flex-start;
  animation: ${fadeIn} 0.3s ease-out;
  animation-delay: 0.4s;

  @media (min-width: 1024px) {
    background: var(--secondary-background-selected-color);
    padding: 0.25rem;
    border-radius: 8px;
    flex-direction: row;
  }
`;

export const Btn = styled(Button).withConfig({
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<BtnProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media only screen and ${media.xl} {
    justify-content: center;
  }

  width: 100%;

  &:hover {
    background-color: rgba(var(--primary-rgb-color), 0.05);
  }

  ${(props) =>
    props.isActive &&
    css`
      background-color: rgba(var(--primary-rgb-color), 0.05);
    `};

  & > span:first-of-type {
    padding: 0.625rem;
    border-radius: 10px;
    margin-right: 0.5rem;

    color: ${(props) => `var(--${props.color}-color)`};

    background-color: rgba(var(--primary-rgb-color), 0.05);
  }
`;
