import React from 'react';
import * as S from './References.styles';
import { LinkedinOutlined } from '@ant-design/icons';

export const References: React.FC = () => {
  return (
    <S.ReferencesWrapper>
      <S.Text>
        &copy; {new Date().getFullYear() + ' '}
        <a href="https://www.debtist.de" target="_blank" rel="noreferrer">
          Debtist{' '}
        </a>
        GmbH
      </S.Text>
      <S.Icons>
        <a href="https://linkedin.com/company/debtist/" target="_blank" rel="noreferrer">
          <LinkedinOutlined />
        </a>
      </S.Icons>
    </S.ReferencesWrapper>
  );
};
